import {NavLink as Link} from "react-router-dom"
import {FormattedMessage} from "react-intl"
import {toAbsoluteUrl} from "src/utils"
import {ComponentTransition, Img} from "src/components/common"
import * as routes from "src/routes"
import {PLATFORM_URL} from "src/uiHelper"


const Header = () => {

  return (
    <div className="header__container">
      <div className="header">
        <div className="header__brand">
          <Link exact={true} to={routes.homeRoute.path}><Img src={toAbsoluteUrl("/media/logo/logo.svg")} alt="..." /></Link>
        </div>

        <div className="header__menu">
          <div className="header__menu__right">
            <ComponentTransition duration={0.5} className="header__menu__left">
              <a href={PLATFORM_URL} target="_blank" rel="noreferrer" className="header__menu__item__text"><FormattedMessage id="MENU.PLATFORM" /></a>
              <Link to={routes.contactUsRoute.path} className="header__menu__item__text"><FormattedMessage id="MENU.CONTACT_US" /></Link>
            </ComponentTransition>
          </div>
        </div>
      </div>
      <div className="header__underline" />
    </div>
  )
}

export default Header
