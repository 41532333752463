import {useEffect, useState} from "react"
import {toAbsoluteUrl} from "src/utils"
import {Img, ContentLoader} from "."


const Gallery = ({ items = [], isFetching }) => {

  const [currentItem, setCurrentItem] = useState()

  useEffect(() => {
    if (items){
      setCurrentItem(items[0])
    }
  }, [items, setCurrentItem])

  return (
    <div className="gallery">
      <div className="gallery__item">
        { !isFetching
          ? <Img className="gallery__item__img" src={currentItem||toAbsoluteUrl('/media/svg/poster.svg')} alt="..." /> 
          : <ContentLoader className="gallery__item__img" /> }
      </div>
      <div className="gallery__showcase">
        { items.map((item) => <Img key={item} onClick={() => setCurrentItem(item)} className={"gallery__showcase__img"} src={item} alt="..." />) }
      </div>
    </div>
  )
}

export default Gallery
