import {Spacing} from "."


export const Card = ({ children, className }) => {
  return <div className={className}>{ children }</div>
}


export const CardImg = ({ src }) => {
  return <img src={src} alt="..." />
}

export const CardTitle = ({ title, subtitle }) => {
  return (<>
    <h4 className="text__title--md w--90">{ title }</h4>
    <Spacing className="p-t-1--33" />
    <p className="text__subtitle--sm w--90">{ subtitle }</p>
    </>)
}
