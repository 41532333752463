import { useEffect, useState } from "react";
import { toAbsoluteUrl } from "src/utils";

const Modal = ({ isOpen, children, onClose = (f) => f }) => {
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      const modalElement = document.getElementById("js-modal");
      if (
        modalElement &&
        !modalElement.contains(event.target) &&
        event.target.id !== "js-modal"
      ) {
        onClose();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleAnimationEnd = () => {
      setIsExiting(false);
    };

    if (!isOpen) {
      setIsExiting(true);
    }

    const modalElement = document.getElementById("js-modal");
    if (modalElement) {
      modalElement.addEventListener("animationend", handleAnimationEnd);
    } else {
      setIsExiting(isOpen);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener("animationend", handleAnimationEnd);
      }
    };
  }, [isOpen]);

  return (
    isExiting && (
      <>
        <div className="modal__background" />
        <div
          id="js-modal"
          className={`modal ${isOpen ? "modal--open" : "modal--exit"}`}
        >
          <img
            src={toAbsoluteUrl("media/svg/close.svg")}
            className="modal--close"
            onClick={onClose}
            alt="..."
          />
          {children}
        </div>
      </>
    )
  );
};

export default Modal;
