import {NotFound} from "src/components/error"
import RouteTransition from './RouteTransition'
import {Switch as BaseSwitch, Route, Redirect, useLocation} from 'react-router-dom'
import { AnimatePresence } from "framer-motion";
//import {useMemo} from "react";

const Switch = ({ basePath, defaultComponent, defaultPath, routes, ...props }) => {

  const location = useLocation();

//  console.log(location)

//  const pageLocation = useMemo(() => {
//    if (location?.state?.doNotAnnimate){
//      return animateOnPage
//    }
//    return location
//  }, [location])

  return <AnimatePresence key={location} exitBeforeEnter>
    <BaseSwitch location={location}>
      { basePath && <Route
        exact
        path={basePath}
        render={() => <Redirect from={basePath} to={defaultPath} />}
      /> }
      { defaultComponent && <Route
        exact
        path={basePath}
        render={defaultComponent}
      /> }
      {Object.keys(routes).map((key) => <RouteTransition key={key} {...routes[key]} { ...props } />)}
      <RouteTransition component={NotFound} />
    </BaseSwitch>
  </AnimatePresence>
}


export default Switch
