import {toAbsoluteUrl} from "src/utils";

export const HOME_FEATURES = [
  {
    title: "HOME.STEP_INTO_NEW_DIMENSION",
    subtitle: "HOME.EXPLORE_PRODUCTS"
  },
  {
    src: toAbsoluteUrl("/media/svg/image1.svg")
  },
  {
    src: toAbsoluteUrl("/media/svg/image2.svg")
  },
  {
    title: "HOME.BUILD_IMMERSIVE_EXPERIENCES",
    subtitle: "HOME.ALLOW_CUSTOMERS_VISUALIZE"
  },
  {
    title: "HOME.MANAGE_3D_AR_CONTENT",
    subtitle: "HOME.STREAMLINE_CONTENT_MANAGEMENT"
  },
  {
    src: toAbsoluteUrl("/media/svg/image3.svg")
  },
  {
    src: toAbsoluteUrl("/media/svg/image4.svg")
  },
  {
    title: "HOME.EFFORTLESSLY_IMPROVE_WORKFLOW",
    subtitle: "HOME.STREAMLINE_FEEDBACK_PROCESS"
  },
]
