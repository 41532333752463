import { FormattedMessage } from "react-intl"
import {Link} from "react-router-dom"

import {homeRoute} from "src/routes"
import {toAbsoluteUrl} from "src/utils"
import {Img} from "../common"


const PageNotFound = () => (
    <div className="spacing--big text--center">
      <Img className="img-center" height="160" width="206" src={toAbsoluteUrl("/media/svg/404.svg")} alt="..." />
      <p className="auth__text--padding text--title text--center text--bold text--secondary">
        <FormattedMessage id="ERROR.PAGE_NOT_FOUND.TITLE" />
      </p>
      <p className="spacing--extra-small spacing-down--medium text--description text__width--extra-small--center">
        <FormattedMessage id="ERROR.PAGE_NOT_FOUND.DESC" />
      </p>
      <Link className="text--center text--description" to={homeRoute.path}><FormattedMessage id="ERROR.BACK_TO_HOME" /></Link>
    </div>
)


export default PageNotFound
