import { FormattedMessage } from "react-intl";
 import { useField } from 'formik';

const InputField = ({
  label,
  placeholder,
  size = "",
  className = "form-control",
  inputType = "text",
  hasError,
  helper,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <div className={size}>
      {label && <label className="control-label">{label}</label>}
      <div className="form-group">
        <div className="form-group-input">
          <input
            {...field}
            {...props}
            value={field?.value || ""}
            className={`form-control ${meta.touched && meta.error && "has-danger"}`}
            type={inputType}
            placeholder={placeholder}
            autoComplete="off"
          />
        </div>
        <div className="form-control__danger">
          {meta.touched && meta.error && <FormattedMessage {...JSON.parse(meta.error)} />}
          {meta.touched && meta.warning && <FormattedMessage {...JSON.parse(meta.warning)} />}
        </div>
        <div className="form-control__helper">{helper}</div>
      </div>
    </div>
  );
};

export default InputField;
