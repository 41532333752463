


const Button = ({ handleClick, className, children, isLoading, ...props }) => {

  return (
    <button disabled={isLoading} onClick={handleClick} type={"button"} className={className + (isLoading ? " btn__disabled ": " ")} { ...props }>
    {isLoading && <div className="loader"></div>}
    { children }
  </button>
  )
}


export default Button
