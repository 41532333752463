export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};


export const WAITING_LIST_API = "/api/v1/draft-invitations"
export const CONTACT_US_API = "/api/v1/contact-us"
