


const Img = (props) => {
  return <img alt='...' loading="lazy" { ...props } />
}



export default Img
