import {useEffect} from "react"
import { animateCSSIn, animateCSSOut } from "src/utils"

const SplashScreen = () => {
  useEffect(() => {

    // Show SplashScreen
    animateCSSIn("#js-splash-screen", "fadeIn", "hide-splash-screen")

    return () => {
      animateCSSOut("#js-splash-screen", "fadeOut", "hide-splash-screen")
    }
  }, [])
  
  return null
}


export default SplashScreen
