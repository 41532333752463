import {useState} from "react";
import { AppContext } from "./hooks/useAppContext"


// Then, create a provider component that will wrap your application
const AppProvider = ({ children }) => {
  // Define your state and any functions that will manipulate it
  const [state, setState] = useState({
    // your initial state values here
  });

  // Define any functions that you want to make available to children components
  const updateState = (newState) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  // Pass the state and functions down to the context provider
  return (
    <AppContext.Provider value={{ state, updateState }}>
      {children}
    </AppContext.Provider>
  );
};


export default AppProvider
