import { Container, GoTop, Switch } from 'src/components/common'
import {AppProvider} from 'src/contexts'
import Header from './components/Header'

import * as allRoutes from 'src/routes'


const ClientLayout = () => {

  return (
    <AppProvider>
      <Header />
      <Container>
        <Switch defaultPath={allRoutes.homeRoute} routes={allRoutes} />
      </Container>
      <GoTop />
    </AppProvider>
  )
}

export default ClientLayout
