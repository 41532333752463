import { lazy } from 'react'

const Home = lazy(() => import('./pages/home/Home'))
const ContactUs = lazy(() => import('./pages/contact-us/ContactUs'))


export const contactUsRoute = {
  path: '/contact',
  component: ContactUs
}

export const homeRoute = {
  path: '/',
  component: Home
}

