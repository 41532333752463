/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Import all the third party stuff
import React from 'react'
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { ErrorBoundary } from 'src/components/error'

// load translation
import {AppI18nProvider, I18nProvider } from "./i18n"

// load style
import './assets/sass/main.scss'

// Import routes
import MainLayout from './layouts/MainLayout'

// loader
import { SplashScreen } from 'src/components/loaders'

// registerd workers
import './workers'

const MOUNT_NODE = document.getElementById('js-root');


const ELEM = (<AppI18nProvider>
    <ErrorBoundary>
      <BrowserRouter>
        {/* Provide `react-intl` context synchronized with Redux state.  */}
        <I18nProvider>
          <React.Suspense fallback={<SplashScreen />}>
            <AnimatePresence exitBeforeEnter initial={true}>
              <MainLayout />
            </AnimatePresence>
          </React.Suspense>
        </I18nProvider>
      </BrowserRouter>
    </ErrorBoundary>
</AppI18nProvider>)

ReactDOM.render(
  ELEM,
  MOUNT_NODE
)

if (process.env.NODE_ENV !== 'production' && module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./routes'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE).render(ELEM, MOUNT_NODE)
  })
}
