import { FormattedMessage } from "react-intl";
import { useField } from "formik";

const TextareaField = ({
  label,
  placeholder,
  disabled,
  size = "",
  className = "form-control",
  row = 5,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <div className={size}>
      <div className="form-group">
        {label && <label className="control-label">{label}</label>}
        <textarea
          {...field}
          {...props}
          disabled={disabled}
          rows={row}
          className={`${className} ${
            meta.touched && meta.error && "has-danger"
          }`}
          placeholder={placeholder}
        />
        <div className="form-control__danger">
          {meta.touched && meta.error && (
            <FormattedMessage {...JSON.parse(meta.error)} />
          )}
          {meta.touched && meta.warning && (
            <FormattedMessage {...JSON.parse(meta.warning)} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TextareaField;
